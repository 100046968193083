.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.shirt {
    font-size: 25px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Roboto', sans-serif;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.name-part{
  font-size:60px;
  line-height: 65px;
  color:rgb(50, 50, 50)
}


.time-part{
  font-size: 35px;
  color:grey;
}

fieldset{
  border: 1px solid rgb(201, 198, 198);
  padding: 20px;
  border-radius: 5px;
  padding-top:0px;
}

legend {
  font-size: 60px;
  float: initial;
  width: initial;
}

.entries{
  padding:20px;
}
.icon{
  font-size: 90px;
  line-height:105px;
}

#footer
{
  position:fixed;
  bottom:0px;
  width:100vw;

}

.hallen{
  height:100vh;
}

 .moede .name-part, .kultursalen .name-part, .hallen .name-part{
  font-size:40px;
}

.omklaedning .name-part {
  font-size:35px;
}
.omklaedning h2 {
  font-size:45px;
  line-height: 55px;
}

.omklaedning .entry, .moede .entry, .kultursalen .entry, .hallen .entry {
  border-left: 10px solid rgb(201, 198, 198);
}

@keyframes border-pulsate {
  0%   { border-color: rgba(67, 194, 135, 1); }
  50%  { border-color: rgba(67, 194, 135, 0.60); }
  100% { border-color: rgba(67, 194, 135, 1); }
}

.omklaedning .active, .moede .active, .kultursalen .active, .hallen .active {
  border-left: 10px solid rgb(67, 194, 135);
  animation: border-pulsate 2s infinite;
}


.entry {
  padding-left:15px;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom:10px;
}

.hallen .active .name-part{
  font-size:60px;
  line-height: 65px;
}


.omklaedning .active .name-part, .moede .active .name-part, .kultursalen .active .name-part {
  font-size:60px;
  line-height:65px;
}

h1 {
  font-size:100px;
}
h2{
  font-size:70px;
}

.omklaedning-entries {
  overflow-y: auto;
  scrollbar-width: none;
  height:87vh;
  scroll-behavior: smooth;
}
.omklaedning-entries::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.omklaedning-entries .card {
  margin-bottom: 10px;
}
:root,
[data-bs-theme=light] {
  --bs-heading-color: #2f2d2d;
}

.card {
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

